import ProjectApi from '@/api/ProjectApi'
import { ProjectDetailType } from '#/ProjectDetailTypes'
import { ProjectMemberType, ProjectEntityItemType } from '#/ProjectTypes'
import { isEmpty, groupBy, keys, camelCase, forOwn, forEach } from 'lodash-es'

export const useProjectStore = defineStore({
  id: 'projectStore',
  state: () => {
    const projectState: {
      loaded: boolean
      projectDetail: ProjectDetailType | null
      projectMemberList: ProjectMemberType[]
      projectEntityList: ProjectEntityItemType[]
    } = {
      loaded: false,
      projectDetail: null,
      projectMemberList: [],
      projectEntityList: []
    }
    return projectState
  },

  actions: {
    // 获取配置
    async projectInfoInit(projectId: string) {
      this.loaded = true
      const projectDetail = new Promise((resolve, reject) => {
        ProjectApi.getProjectDetail(projectId)
          .then((response) => {
            this.projectDetail = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      const projectMemberList = new Promise((resolve, reject) => {
        ProjectApi.getProjectMemberList(projectId)
          .then((response) => {
            const roleOrder: { [key: string]: number } = { SUPER_ADMIN: 1, ADMIN: 2, MEMBER: 3, '': 4 }
            this.projectMemberList = response.data.sort((a, b) => {
              const aOrder = roleOrder[a.role] || 4
              const bOrder = roleOrder[b.role] || 4
              return aOrder - bOrder
            })
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      const projectEntityList = new Promise((resolve, reject) => {
        ProjectApi.getEntityList(projectId)
          .then((response) => {
            this.projectEntityList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })

      try {
        const requestList = [projectDetail, projectMemberList, projectEntityList]
        const response = await Promise.all(requestList)
        this.loaded = false
        return response
      } catch (error: any) {
        this.loaded = false
        return Promise.reject(error)
      }
    },
    /**
     * 获取项目详情
     * @param {string} projectId 项目id
     */
    async fetchProjectDetail(projectId: string) {
      try {
        const response = await ProjectApi.getProjectDetail(projectId)
        this.projectDetail = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 设置项目详情
     */
    setProjectDetail(projectDetail: ProjectDetailType) {
      if (!projectDetail) return
      this.projectDetail = projectDetail
    },
    /**
     * 获取项目成员列表
     * @param {string} projectId 项目id
     */
    async fetchProjectMemberList(projectId: string) {
      try {
        const response = await ProjectApi.getProjectMemberList(projectId)
        this.projectMemberList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity列表
     * @param {string} projectId 项目id
     */
    async fetchEntityList(projectId: string) {
      try {
        const response = await ProjectApi.getEntityList(projectId)
        this.projectEntityList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {
    entityPermission: () => {
      return (user?: any) => {
        let permission: any = {}
        if (!isEmpty(user)) {
          permission = user.permission
        }
        const groups = groupBy(keys(permission), (key: any) =>
          key.replace(
            /^(.*?)Erp|ViewDetail|ViewList|View|Update|BalanceAdd|BalanceVerify|BalanceExport|BalanceDelete|Generate|Export|Verify|Posting|Sync|Categorize|Execute|Create|Delete$/,
            '$1'
          )
        )
        const result: any = {}
        forOwn(groups, (values: any, key: any) => {
          const newKey = camelCase(key)
          const newObj: any = {}
          forEach(values, (value: any) => {
            newObj[camelCase(value.replace(key, ''))] = permission[value]
          })
          result[newKey] = newObj
        })
        return result
      }
    }
  }
})
